<template>
<div>
<a-modal class="contact-message" v-if="mode=='pc'" :width="540" v-model="visible" :centered="true" :closable="false" :maskClosable="false">
    <div class="close-btn" @click="onClickClose()">닫기</div>
    <div class="contact-contents">
        <div class="title"><img style="height:144px" src="@/assets/images/v4-icon/contact-title.png" /></div>
        <a-row style="margin-top:34px">
            <a-col :span="20" :offset="2">
                <a-row>
                    <a-col :span="4">
                        <div class="stitle">*문의유형</div>
                    </a-col>
                    <a-col :span="20" style="margin-bottom:20px">
                        <div>
                            <a-select :getPopupContainer="triggerNode => triggerNode.parentNode" placeholder="문의 유형을 선택해주세요" optionFilterProp="children" size="large" style="width: 100%" @change="handleChange">
                                <a-select-option value="순위관리서비스">상위노출 마케팅</a-select-option>
                                <a-select-option value="페이스북 마케팅 대행">페이스북/인스타그램 마케팅 대행</a-select-option>
                                <a-select-option value="검색광고 대행">검색광고 대행</a-select-option>
                                <a-select-option value="공동구매">공동구매</a-select-option>
                                <a-select-option value="틱톡마케팅">광고대행사 협업</a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                    <a-col :span="4" style="margin-bottom:20px">
                        <div class="stitle">*성함</div>
                    </a-col>
                    <a-col :span="20" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" style="width:100%" v-model="uname">

                            </a-input>
                        </div>
                    </a-col>

                    <a-col :span="4" style="margin-bottom:20px">
                        <div class="stitle">*업체명</div>
                    </a-col>
                    <a-col :span="20" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" style="width:100%" v-model="cname">

                            </a-input>
                        </div>
                    </a-col>

                    <a-col :span="4" style="margin-bottom:20px">
                        <div class="stitle">*이메일</div>
                    </a-col>

                    <a-col :span="9" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" style="width:100%" v-model="email1">

                            </a-input>
                        </div>
                    </a-col>
                    <a-col :span="2" style="margin-bottom:20px">
                        <div style="text-align:center;padding-top:12px">
                            @
                        </div>
                    </a-col>
                    <a-col :span="9" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" style="width:100%" v-model="email2">

                            </a-input>
                        </div>
                    </a-col>

                    <a-col :span="4" style="margin-bottom:20px">
                        <div class="stitle">*전화번호</div>
                    </a-col>
                    <a-col :span="6" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" type="number" style="width:100%" v-model="phone1">

                            </a-input>
                        </div>
                    </a-col>
                    <a-col :span="1" style="margin-bottom:20px">
                        <div style="text-align:center;padding-top:12px">
                            -
                        </div>
                    </a-col>
                    <a-col :span="6" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" type="number" style="width:100%" v-model="phone2">

                            </a-input>
                        </div>
                    </a-col>
                    <a-col :span="1" style="margin-bottom:20px">
                        <div style="text-align:center;padding-top:12px">
                            -
                        </div>
                    </a-col>
                    <a-col :span="6" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" type="number" style="width:100%" v-model="phone3">

                            </a-input>
                        </div>
                    </a-col>
                    <a-col :span="24">
                        <a-textarea v-model="contents" placeholder="문의 내용을 입력해주세요" :rows="8" />
                    </a-col>
                    <a-col :span="24" style="margin-top:20px">
                        <div><span class="c-font1 ">선호 상담 유형</span></div>
                        <div style="margin-bottom:4px;font-size:12px">스토어링크는 전화상담을 기본으로 하고 있습니다.</div>
                        <div style="display:flex;width:100%"> 
                            <a-radio-group v-model="prefer" style="width:55%;margin-top:10px" :options="plainOptions" />
                           <a-input style="width:45%" type="text" v-mask="'##시'" placeholder="희망시간대 입력해주세요" v-model="time"> </a-input>
                        </div>
                    </a-col>
                    <a-col :span="24" style="margin-top:20px">
                        <div>
                            <a-checkbox @change="onChangeCheck">
                                <span class="c-font2">전체동의하기</span>
                            </a-checkbox>
                        </div>
                        <div style="margin-top:13px;margin-bottom:13px">
                            <a-checkbox v-model="c1">
                                <span class="c-font3">(필수) 개인정보보호정책에 동의합니다.</span>
                            </a-checkbox>
                        </div>
                        <div>
                            <a-checkbox v-model="c2">
                                <span class="c-font3">(선택) 마케팅 수신 동의</span>
                            </a-checkbox>
                        </div>
                    </a-col>

                </a-row>

            </a-col>

        </a-row>
    </div>
    <div class="btn-div">
        <div class="btn" @click="onSendData()" style="margin-left:5px">
            상담신청하기
        </div>
    </div>

</a-modal>
<a-modal class="contact-message" v-if="mode=='mobile'"  v-model="visible" :centered="true" :closable="false" :maskClosable="false">
    <div class="close-btn" @click="onClickClose()">닫기</div>
    <div class="contact-contents">
        <div class="title"><img style="width:100%" src="@/assets/images/v4-icon/contact-title.png" /></div>
        <a-row style="margin-top:34px">
            <a-col :span="24">
                <a-row>
                    <a-col :span="6">
                        <div class="stitle">*문의유형</div>
                    </a-col>
                    <a-col :span="18" style="margin-bottom:20px">
                        <div>
                            <a-select :getPopupContainer="triggerNode => triggerNode.parentNode" placeholder="문의 유형을 선택해주세요" optionFilterProp="children" size="large" style="width: 100%" @change="handleChange">
                                <a-select-option value="순위관리서비스">상위노출 마케팅</a-select-option>
                                <a-select-option value="페이스북 마케팅 대행">페이스북/인스타그램 마케팅 대행</a-select-option>
                                <a-select-option value="검색광고 대행">검색광고 대행</a-select-option>
                                <a-select-option value="공동구매">공동구매</a-select-option>
                                <a-select-option value="틱톡마케팅">광고대행사 협업</a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                    <a-col :span="6" style="margin-bottom:20px">
                        <div class="stitle">*성함</div>
                    </a-col>
                    <a-col :span="18" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" style="width:100%" v-model="uname">

                            </a-input>
                        </div>
                    </a-col>

                    <a-col :span="6" style="margin-bottom:20px">
                        <div class="stitle">*업체명</div>
                    </a-col>
                    <a-col :span="18" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" style="width:100%" v-model="cname">

                            </a-input>
                        </div>
                    </a-col>

                    <a-col :span="6" style="margin-bottom:20px">
                        <div class="stitle">*이메일</div>
                    </a-col>

                    <a-col :span="8" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" style="width:100%" v-model="email1">

                            </a-input>
                        </div>
                    </a-col>
                    <a-col :span="2" style="margin-bottom:20px">
                        <div style="text-align:center;padding-top:12px">
                            @
                        </div>
                    </a-col>
                    <a-col :span="8" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" style="width:100%" v-model="email2">

                            </a-input>
                        </div>
                    </a-col>

                    <a-col :span="6" style="margin-bottom:20px">
                        <div class="stitle">*전화번호</div>
                    </a-col>
                    <a-col :span="5" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" type="number" style="width:100%" v-model="phone1">

                            </a-input>
                        </div>
                    </a-col>
                    <a-col :span="1" style="margin-bottom:20px">
                        <div style="text-align:center;padding-top:12px">
                            -
                        </div>
                    </a-col>
                    <a-col :span="5" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" type="number" style="width:100%" v-model="phone2">

                            </a-input>
                        </div>
                    </a-col>
                    <a-col :span="1" style="margin-bottom:20px">
                        <div style="text-align:center;padding-top:12px">
                            -
                        </div>
                    </a-col>
                    <a-col :span="6" style="margin-bottom:20px">
                        <div>
                            <a-input class="s-input1" type="number" style="width:100%" v-model="phone3">

                            </a-input>
                        </div>
                    </a-col>
                    <a-col :span="24">
                        <a-textarea v-model="contents" placeholder="문의 내용을 입력해주세요" :rows="8" />
                    </a-col>
                    <a-col :span="24" style="margin-top:20px">
                        <div><span class="c-font1 ">선호 상담 유형</span></div>
                        <div style="margin-bottom:4px;font-size:12px">스토어링크는 전화상담을 기본으로 하고 있습니다.</div>
                        <div style="width:100%">
                            <a-radio-group v-model="prefer" style="width:100%;margin-top:10px" :options="plainOptions" />
                        </div>
                        <div style="margin-top:20px">
                              <a-input type="text" v-mask="'##시'" placeholder="희망시간대 입력해주세요" v-model="time"> </a-input>
                         </div>
                    </a-col>
                    <a-col :span="24" style="margin-top:20px">
                        <div>
                            <a-checkbox @change="onChangeCheck">
                                <span class="c-font2">전체동의하기</span>
                            </a-checkbox>
                        </div>
                        <div style="margin-top:13px;margin-bottom:13px">
                            <a-checkbox v-model="c1">
                                <span class="c-font3">(필수) 개인정보보호정책에 동의합니다.</span>
                            </a-checkbox>
                        </div>
                        <div>
                            <a-checkbox v-model="c2">
                                <span class="c-font3">(선택) 마케팅 수신 동의</span>
                            </a-checkbox>
                        </div>
                    </a-col>

                </a-row>

            </a-col>

        </a-row>
    </div>
    <div class="btn-div">
        <div class="btn" @click="onSendData()" style="margin-left:5px">
            상담신청하기
        </div>
    </div>

</a-modal>
</div>
</template>

<script>
import {
  showError,
  showSuccess,
  getCurrentTimeNew
} from "../../components/fnc.js";
import firebase from "firebase";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "pc"
    }
  },
  methods: {
    handleChange(e) {
      this.type = e;
    },
    onChangeCheck(e) {
      if (e.target.checked) {
        this.c1 = true;
        this.c2 = true;
      } else {
        this.c1 = false;
        this.c2 = false;
      }
    },
    onSend() {
      var self = this;
      var d2 = new Date();
      var d = getCurrentTimeNew();
      var self = this;

      var newPostKey = firebase
        .database()
        .ref()
        .child("bbs/")
        .push().key;
      this.$http
        .post(
          "https://us-central1-store-link-49fa1.cloudfunctions.net/invokeMsg8"
        )
        .then(function(x) {});

      firebase
        .database()
        .ref("bbs/" + newPostKey)
        .set({
          createdAt: d2.getTime(),
          eventdttm: d,
          reverseCreatedAt: -d2.getTime(),
          type: this.type,
          uname: this.uname,
          cname: this.cname,
          phone: this.phone1 + "-" + this.phone2 + "-" + this.phone3,
          email: this.email1 + "@" + this.email2,
          contents: this.contents,
          prefer: this.prefer,
          time: this.time ? this.time : "-",
          c2: this.c2
        })
        .then(function(result) {
          // self.setUserInfo(self.deposit);

          showSuccess({
            notify: self.$vs.notify,
            msg: "성공적으로 전송 되었습니다."
          });
        })
        .catch(error => {});

      // 문의하기 양식전송 완료
      dataLayer.push({
        event: "service_inquiry_sent"
      });
      fbq("track", "SubmitApplication");
      window.kakaoPixel("845593645083740063").participation(" Consulting");
    },
    onSendData() {
      //to-do
      if (this.type == "") {
        showError({
          notify: this.$vs.notify,
          msg: "문의 유형을 선택해주세요"
        });
      } else if (this.uname == "" || this.cname == "" || this.contents == "") {
        showError({
          notify: this.$vs.notify,
          msg: "필수 항목을 모두 작성해주세요."
        });
      } else if (this.c1 == false) {
        showError({
          notify: this.$vs.notify,
          msg: "필수 동의 항목을 채크 해주셔야 합니다"
        });
      } else if (this.prefer == "") {
        showError({
          notify: this.$vs.notify,
          msg: "선호 상담 유형을 선택해주세요"
        });
      } else {
        this.onSend();
        this.$emit("clicked", true);
        this.$emit("closed", true);
      }
    },
    onClickClose() {
      this.$emit("closed", true);
    }
  },
  data() {
    return {
      plainOptions: ["전화", "카카오톡", "무관"],
      uname: "",
      cname: "",
      email1: "",
      email2: "",
      phone1: "",
      phone2: "",
      phone3: "",
      prefer: "",
      type: "",
      time: "",
      contents: "",
      prefer: "",
      c1: false,
      c2: false
    };
  }
};
</script>

<style>
.contact-message .ant-modal-footer {
  display: none;
}

/* .message .ant-modal-content {
  width: 360px !important;
  height: 189px !important;
} */

.contact-message .btn {
  width: 118px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #ffffff;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

.contact-message .btn-div {
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-message .close-btn {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;
  cursor: pointer;
  color: #828282;
}

.contact-message .contact-contents {
  margin-top: 30px;
  margin-bottom: 21px;
}

.contact-contents .title {
  text-align: center;
}

.contact-contents div {
  text-align: left;

  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #333333;
}

.contact-contents input {
  height: 45px;
  border-radius: 5px !important;
}

.contact-contents textarea {
  border-radius: 5px !important;
  margin-left: 5px;
}

.contact-contents .ant-select-selection {
  height: 45px;
  border-radius: 5px !important;
}

.contact-contents .stitle {
  padding-top: 12px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #333333;
}

.contact-contents .ant-select-selection-selected-value {
  padding-top: 4px;
}

.contact-contents .ant-select-selection__rendered {
  padding-top: 7px;
}

.c-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #333333;
}

.c-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #000000;
}

.c-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #828282;
}
</style>
